import { createReducer } from "utils/AppsmithUtils";
import { ReduxAction, ReduxActionTypes } from "constants/ReduxActionConstants";

const initialState: TemplateWidgetsState = {
  customTemplates: [],
};

const templateWidgetReducer = createReducer(initialState, {
  [ReduxActionTypes.SET_TEMPLATE_WIDGET]: (
    state: TemplateWidgetsState,
    action: ReduxAction<{ customTemplates: string }>,
  ) => {
    return { ...state, customTemplates: action.payload.customTemplates };
  },
});

export interface TemplateWidgetsState {
  customTemplates: any;
}

export default templateWidgetReducer;
