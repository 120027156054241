import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { AppState } from "reducers";
import { getCurrentOrg } from "selectors/organizationSelectors";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import CopyToClipBoard from "components/designSystems/appsmith/CopyToClipBoard";
import {
  isPermitted,
  PERMISSION_TYPE,
} from "../Applications/permissionHelpers";
import { getApplicationViewerPageURL } from "constants/routes";
import OrgInviteUsersForm from "./OrgInviteUsersForm";
import { getCurrentUser } from "selectors/usersSelectors";
import Text, { TextType } from "components/ads/Text";
import Toggle from "components/ads/Toggle";
import { ANONYMOUS_USERNAME } from "constants/userConstants";

import TextInput from "../../components/ads/TextInput";
import "./AppInviteUsersForm.css";
import { put } from "redux-saga/effects";
import { StyledSwitch } from "components/propertyControls/StyledControls";

const Title = styled.div`
  padding: 10px 0px;
`;

const ShareWithPublicOption = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: space-between;
`;

const ShareToggle = styled.div`
  flex-basis: 48px;
  height: 23px;
`;

const AppInviteUsersForm = (props: any) => {
  const {
    isFetchingApplication,
    isChangingViewAccess,
    currentApplicationDetails,
    changeAppViewAccess,
    applicationId,
    fetchCurrentOrg,
    updateApplication,
    updateApplicationSettings,
    currentOrg,
    currentUser,
    defaultPageId,
  } = props;
  const color = currentApplicationDetails?.color;
  const settings = currentApplicationDetails.settings_data || {
    isVerifyKeyCloak: false,
    IdKeycloak: "",
    isVerify: false,
    tokenUrl: "",
    isMenu: true,
  };
  const userOrgPermissions = currentOrg?.userPermissions ?? [];
  const userAppPermissions = currentApplicationDetails?.userPermissions ?? [];
  const canInviteToOrg = isPermitted(
    userOrgPermissions,
    PERMISSION_TYPE.INVITE_USER_TO_ORGANIZATION,
  );
  const canShareWithPublic = isPermitted(
    userAppPermissions,
    PERMISSION_TYPE.MAKE_PUBLIC_APPLICATION,
  );

  const getViewApplicationURL = () => {
    const appViewEndPoint = getApplicationViewerPageURL(
      applicationId,
      defaultPageId,
    );
    return window.location.origin.toString() + appViewEndPoint;
  };

  useEffect(() => {
    if (currentUser.name !== ANONYMOUS_USERNAME) {
      fetchCurrentOrg(props.orgId);
    }
  }, [props.orgId, fetchCurrentOrg, currentUser.name]);

  const handleToken = useCallback(
    (e: any) => {
      updateApplicationSettings(currentApplicationDetails.id, {
        settings_data: { ...settings, isVerify: !settings.isVerify },
      });
      setTimeout(() => {
        changeAppViewAccess(applicationId, true);
      }, 2000);
    },
    [settings],
  );

  const handleKeyCloak = useCallback(
    (e: any) => {
      updateApplicationSettings(currentApplicationDetails.id, {
        settings_data: {
          ...settings,
          isVerifyKeyCloak: !settings.isVerifyKeyCloak,
        },
      });
    },
    [settings],
  );
  const handleMenu = useCallback(
    (e: any) => {
      updateApplicationSettings(currentApplicationDetails.id, {
        settings_data: {
          ...settings,
          isMenu: !settings.isMenu,
        },
      });
    },
    [settings],
  );
  const handleTokenUrl = useCallback(
    (e: any) => {
      updateApplicationSettings(currentApplicationDetails.id, {
        settings_data: { ...settings, tokenUrl: e },
      });
      setTimeout(() => {
        changeAppViewAccess(applicationId, true);
      }, 2000);
    },
    [settings],
  );
  const handleTokenCss = useCallback(
    (e: any) => {
      updateApplicationSettings(currentApplicationDetails.id, {
        settings_data: { ...settings, css: e },
      });
    },
    [settings],
  );
  const handleKeyCloakId = useCallback(
    (e: any) => {
      updateApplicationSettings(currentApplicationDetails.id, {
        settings_data: { ...settings, IdKeycloak: e },
      });
    },
    [settings],
  );

  return (
    <>
      {canShareWithPublic && (
        <>
          <ShareWithPublicOption>
            <Text type={TextType.H5}>Make the application public</Text>
            <ShareToggle>
              {currentApplicationDetails && (
                <Toggle
                  isLoading={isChangingViewAccess || isFetchingApplication}
                  value={currentApplicationDetails.isPublic}
                  disabled={isChangingViewAccess || isFetchingApplication}
                  onToggle={() => {
                    if (!currentApplicationDetails.isPublic) {
                      updateApplicationSettings(currentApplicationDetails.id, {
                        settings_data: { ...settings, isVerifyKeyCloak: false },
                      });
                    } else {
                      updateApplicationSettings(currentApplicationDetails.id, {
                        settings_data: { ...settings, isVerify: false },
                      });
                    }
                    changeAppViewAccess(
                      applicationId,
                      !currentApplicationDetails.isPublic,
                    );
                  }}
                />
              )}
            </ShareToggle>
          </ShareWithPublicOption>
        </>
      )}
      {currentApplicationDetails.isPublic && (
        <>
          <ShareWithPublicOption>
            <span style={{ color: "white" }}>Is Verify Token</span>
            <ShareToggle>
              <StyledSwitch
                onChange={handleToken}
                checked={settings.isVerify}
                large
              />
            </ShareToggle>
          </ShareWithPublicOption>
          <TextInput
            className="input_white_to_verify_token"
            placeholder="Verify Token URl"
            onChange={handleTokenUrl}
            defaultValue={settings.tokenUrl || ""}
          ></TextInput>
        </>
      )}
      {!currentApplicationDetails.isPublic && (
        <>
          <ShareWithPublicOption>
            <span style={{ color: "white" }}>Is Verify Keycloak</span>

            <ShareToggle>
              <StyledSwitch
                onChange={handleKeyCloak}
                checked={settings.isVerifyKeyCloak}
                large
              />
            </ShareToggle>
          </ShareWithPublicOption>
          {settings.isVerifyKeyCloak && (
            <TextInput
              className="input_white_to_verify_token"
              placeholder="Verify Id Keycloak"
              onChange={handleKeyCloakId}
              defaultValue={settings.IdKeycloak || ""}
            ></TextInput>
          )}
        </>
      )}
      <Title>
        <Text type={TextType.H5}>
          Get Shareable link for this for this application
        </Text>
      </Title>
      <CopyToClipBoard copyText={getViewApplicationURL()} />
      <ShareWithPublicOption style={{ marginTop: "15px" }}>
        <span style={{ color: "white" }}>Is add menu</span>
        <ShareToggle>
          <StyledSwitch onChange={handleMenu} checked={settings.isMenu} large />
        </ShareToggle>
      </ShareWithPublicOption>
      <Title>
        <Text type={TextType.H5}>Enter url for custom css</Text>
      </Title>
      <TextInput
        className="input_white_to_verify_token"
        placeholder="Enter Url"
        onChange={handleTokenCss}
        defaultValue={settings.css || ""}
      ></TextInput>
      {canInviteToOrg && (
        <OrgInviteUsersForm orgId={props.orgId} isApplicationInvite={true} />
      )}
    </>
  );
};

export default connect(
  (state: AppState) => {
    return {
      currentOrg: getCurrentOrg(state),
      currentUser: getCurrentUser(state),
      currentApplicationDetails: state.ui.applications.currentApplication,
      defaultPageId: state.entities.pageList.defaultPageId,
      isFetchingApplication: state.ui.applications.isFetchingApplication,
      isChangingViewAccess: state.ui.applications.isChangingViewAccess,
    };
  },
  (dispatch: any) => ({
    changeAppViewAccess: (applicationId: string, publicAccess: boolean) =>
      dispatch({
        type: ReduxActionTypes.CHANGE_APPVIEW_ACCESS_INIT,
        payload: {
          applicationId,
          publicAccess,
        },
      }),
    updateApplication: (id: string, data: any) =>
      dispatch({
        type: ReduxActionTypes.UPDATE_APPLICATION_COLOR,
        payload: {
          id,
          ...data,
        },
      }),
    updateApplicationSettings: (id: string, data: any) =>
      dispatch({
        type: ReduxActionTypes.UPDATE_APPLICATION_SETTINGS,
        payload: {
          id,
          ...data,
        },
      }),
    fetchCurrentOrg: (orgId: string) =>
      dispatch({
        type: ReduxActionTypes.FETCH_CURRENT_ORG,
        payload: {
          orgId,
        },
      }),
  }),
)(AppInviteUsersForm);
