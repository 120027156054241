import React, { useEffect } from "react";
// import styled from "styled-components";
import { put, select } from "redux-saga/effects";
import { WidgetProps } from "widgets/BaseWidget";
import { RenderModes } from "constants/WidgetConstants";
import WidgetFactory from "utils/WidgetFactory";
import { ContainerWidgetProps } from "widgets/ContainerWidget";
import AnalyticsUtil from "utils/AnalyticsUtil";
// import {LayoutPanel} from "fundamental-react/lib/LayoutPanel";
// const LayoutPanel = require("fundamental-react/lib/LayoutPanel");
import {
  getCurrentApplicationId,
  getIsPublishingApplication,
} from "../../selectors/editorSelectors";
import { getAppStoreName } from "../../constants/AppConstants";
import { rootSaga } from "../../sagas";
import {
  updateAppGlobalStore,
  updateAppStore,
  updatePage,
} from "../../actions/pageActions";
import { EventType } from "../../constants/ActionConstants";
import { useSelector } from "react-redux";
import { getCurrentApplicationSettingsData } from "../../selectors/applicationSelectors";
import { useDynamicAppLayout } from "../../utils/hooks/useDynamicAppLayout";

// const PageView = styled.div<{ width: number }>`
//   height: 100%;
//   position: relative;
//   width: ${props => props.width}px;
//   margin: 0 auto;
// `;

type AppPageProps = {
  dsl: ContainerWidgetProps<WidgetProps>;
  pageName?: string;
  pageId?: string;
  appName?: string;
  executeAction?: (action: any) => void;
};

export const AppPage = (props: AppPageProps) => {
  useDynamicAppLayout();
  useEffect(() => {
    AnalyticsUtil.logEvent("PAGE_LOAD", {
      pageName: props.pageName,
      pageId: props.pageId,
      appName: props.appName,
      mode: "VIEW",
    });
  }, [props.pageId, props.pageName]);

  // useEffect(() => {
  //   const token = getParams("auth_token");
  //   if (token) {
  //     if (props.executeAction) {
  //       props.executeAction({
  //         dynamicString: `{{storeValue('auth_token','${token}')}}`,
  //         event: {
  //           type: EventType.ON_CLICK,
  //           callback: () => {
  //             console.log("finish auth_token");
  //           },
  //         },
  //       });
  //     }
  //   }
  // }, []);

  // return (
  //   <PageView width={props.dsl.rightColumn}>
  //     {props.dsl.widgetId &&
  //       WidgetFactory.createWidget(props.dsl, RenderModes.PAGE)}
  //   </PageView>
  // );
  const settings = useSelector(getCurrentApplicationSettingsData);
  return (
    // <LayoutPanel>
    //   <LayoutPanel.Body style={{ padding: "0rem 2.5rem" }}>

    <div
      className="customClassWithoutHeader CUSTOM_MAIN_CONTAINER"
      style={{ padding: `0rem ${settings?.isMenu ? "3.5rem" : "0rem"}` }}
    >
      {props.dsl.widgetId &&
        WidgetFactory.createWidget(props.dsl, RenderModes.PAGE)}
    </div>
    //   </LayoutPanel.Body>
    //  </LayoutPanel>
  );
};

export default AppPage;
export function getParams(params: string) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(params);
}
