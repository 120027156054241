import React, { useEffect, useState } from "react";
import ApplicationApi from "../../api/ApplicationApi";
import ApplicationCard from "./ApplicationCard";
import { PaddingWrapper } from "./index";

function getPageLogin() {
  return window.location.origin + "/user/login";
}

export const ApplicationAndUser = (props: any) => {
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    (async () => {
      const data: any = await ApplicationApi.getAllApplicationAndUser();
      if (data.data) {
        setData(findDefaultPage(data.data.applications));
      } else {
        window.location.href = getPageLogin();
      }
    })();
  }, []);

  return (
    <div
      style={{
        marginTop: "48px",
        height: "calc(100vh - 48px)",
        display: "flex",
        background: "rgb(28, 28, 30)",
      }}
    >
      {data.map((item: any) => {
        return (
          <PaddingWrapper key={item.id}>
            <ApplicationCard
              key={item.id}
              application={item}
              orgId={item.organizationId}
              delete={() => {
                console.log();
              }}
              update={() => {
                console.log();
              }}
              duplicate={() => {
                console.log();
              }}
            />
          </PaddingWrapper>
        );
      })}
    </div>
  );
};

function findDefaultPage(data: any) {
  const newData = [];
  for (const item of data) {
    const page = item.pages.find((item: any) => {
      return item.isDefault;
    });
    newData.push({ ...item, defaultPageId: page.id });
  }
  return newData;
}

export default ApplicationAndUser;
