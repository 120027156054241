import { theme } from "constants/DefaultTheme";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { debounce, maxBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "reducers";
import { getWidget, getWidgets } from "sagas/selectors";
import { getAppMode } from "selectors/applicationSelectors";
import {
  getCurrentApplicationLayoutSettings,
  getCurrentPageId,
} from "selectors/editorSelectors";
import { useWindowSizeHooks } from "./dragResizeHooks";

export const useDynamicAppLayout = () => {
  const { width: screenWidth, height: screenHeight } = useWindowSizeHooks();
  const mainContainer = useSelector((state: AppState) => getWidget(state, "0"));
  const currentPageId = useSelector(getCurrentPageId);
  const appMode = useSelector(getAppMode);
  const appLayoutSettings = useSelector(getCurrentApplicationLayoutSettings);
  const widgets = useSelector(getWidgets);
  const dispatch = useDispatch();
  const [isScrollBar, setIsScrollBar] = useState(false);

  const calculateFluidMaxWidth = (
    screenWidth: number,
    layoutMaxWidth: number,
    isMobile = false,
    isMenu = false,
  ) => {
    let screenWidthWithBuffer;
    if (isMobile) {
      screenWidthWithBuffer = screenWidth + 6;
    } else {
      if (isMenu) {
        if (isScrollBar) {
          screenWidthWithBuffer = screenWidth - 70;
        } else {
          screenWidthWithBuffer = screenWidth - 56;
        }
      } else {
        if (isScrollBar) {
          screenWidthWithBuffer = screenWidth - 12;
        } else {
          screenWidthWithBuffer = screenWidth + 10;
        }
      }
    }
    const widthToFill =
      appMode === "EDIT"
        ? screenWidthWithBuffer - parseInt(theme.sidebarWidth)
        : screenWidthWithBuffer;

    if (layoutMaxWidth < 0) {
      return widthToFill;
    } else {
      return widthToFill < layoutMaxWidth ? widthToFill : layoutMaxWidth;
    }
  };

  const resizeToLayout = (screenWidth: number) => {
    const type = "FLUID";
    const width = 1925;
    const layoutWidth =
      type === "FLUID"
        ? calculateFluidMaxWidth(
            screenWidth,
            width,
            appLayoutSettings?.isMobile,
            appLayoutSettings?.isMenu,
          )
        : width;
    const { rightColumn } = mainContainer;
    if (rightColumn !== layoutWidth) {
      dispatch({
        type: ReduxActionTypes.UPDATE_CANVAS_LAYOUT,
        payload: {
          width: layoutWidth,
        },
      });
    }
  };

  const debouncedResize = useCallback(debounce(resizeToLayout, 250), [
    mainContainer,
  ]);
  const dispatchNewHeight = (height: number) => {
    dispatch({
      type: ReduxActionTypes.UPDATE_CANVAS_LAYOUT_HEIGHT,
      payload: {
        height: height,
      },
    });
  };
  const resizeToLayoutHeight = () => {
    if (appMode !== "EDIT") {
      const max = maxBy(Object.values(widgets), (item: any) => {
        if (item.widgetName == "MainContainer") {
          return 0;
        }
        if (item.type == "CANVAS_WIDGET") {
          return 0;
        }
        return item.bottomRow;
      });
      if (max) {
        if (appLayoutSettings?.isMenu) {
          const widowHeight = window.innerHeight - 44;
          const min_height = max.bottomRow * max.parentRowSpace;
          if (widowHeight > min_height) {
            setIsScrollBar(false);
            dispatchNewHeight(widowHeight);
          } else {
            setIsScrollBar(true);
            dispatchNewHeight(min_height);
          }
        } else {
          const min_height = max.bottomRow * max.parentRowSpace;
          if (window.innerHeight > min_height) {
            setIsScrollBar(false);
            dispatchNewHeight(window.innerHeight);
          } else {
            setIsScrollBar(true);
            dispatchNewHeight(min_height);
          }
        }
        resizeToLayout(screenWidth);
      }
    }
  };

  const debouncedResizeHeight = useCallback(
    debounce(resizeToLayoutHeight, 250),
    [mainContainer],
  );

  useEffect(() => {
    debouncedResizeHeight();
  }, [widgets, screenHeight]);

  useEffect(() => {
    debouncedResize(screenWidth);
  }, [screenWidth]);

  useEffect(() => {
    resizeToLayout(screenWidth);
  }, [appLayoutSettings, currentPageId]);
};
