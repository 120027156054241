import React from "react";
import BaseControl, { ControlProps } from "./BaseControl";
import { ControlType } from "constants/PropertyControlConstants";
import { button } from "@storybook/addon-knobs";

class CreateTemplate extends BaseControl<CreateTemplateProps> {
  getControlType(): ControlType {
    return "CREATE_TEMPLATE";
  }

  render() {
    const { configProperty, label } = this.props;
    console.log(this.props, "test props create template))");
    return <button>test</button>;
  }
}

export type CreateTemplateProps = ControlProps;

export default CreateTemplate;
