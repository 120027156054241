import { createReducer } from "utils/AppsmithUtils";
import { ReduxAction, ReduxActionTypes } from "constants/ReduxActionConstants";
import { User } from "constants/userConstants";
import { select } from "redux-saga/effects";
import { getCurrentApplicationId } from "../../selectors/editorSelectors";
import { getAppStoreName } from "../../constants/AppConstants";

export enum APP_MODE {
  EDIT = "EDIT",
  PUBLISHED = "PUBLISHED",
}

export type AuthUserState = {
  username: string;
  email: string;
  id: string;
};

export type UrlDataState = {
  queryParams: Record<string, string>;
  protocol: string;
  host: string;
  hostname: string;
  port: string;
  pathname: string;
  hash: string;
  fullPath: string;
};

export type AppDataState = {
  mode?: APP_MODE;
  user: AuthUserState;
  URL: UrlDataState;
  store: Record<string, unknown>;
  globalStore: Record<string, unknown>;
  socketData: Record<string, unknown>;
};

export function getParams(params: string) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(params);
}

function getToken() {
  const token = getParams("auth_token");
  if (token) {
    const action = { key: "auth_token", value: token };
    const appStoreName = getAppStoreName();
    const existingStore = localStorage.getItem(appStoreName) || "{}";
    const storeObj = JSON.parse(existingStore);
    storeObj[action.key] = action.value;
    const storeString = JSON.stringify(storeObj);
    localStorage.setItem(appStoreName, storeString);
  }
}

getToken();

const initialState: AppDataState = {
  user: {
    username: "",
    email: "",
    id: "",
  },
  URL: {
    queryParams: {},
    protocol: "",
    host: "",
    hostname: "",
    port: "",
    pathname: "",
    hash: "",
    fullPath: "",
  },
  store: {},
  globalStore: {},
  socketData: {},
};

const appReducer = createReducer(initialState, {
  [ReduxActionTypes.SET_APP_MODE]: (
    state: AppDataState,
    action: ReduxAction<APP_MODE>,
  ) => {
    return {
      ...state,
      mode: action.payload,
    };
  },
  [ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS]: (
    state: AppDataState,
    action: ReduxAction<User>,
  ) => {
    return {
      ...state,
      user: action.payload,
    };
  },
  [ReduxActionTypes.SET_URL_DATA]: (
    state: AppDataState,
    action: ReduxAction<UrlDataState>,
  ) => {
    return {
      ...state,
      URL: action.payload,
    };
  },
  [ReduxActionTypes.UPDATE_APP_STORE]: (
    state: AppDataState,
    action: ReduxAction<Record<string, unknown>>,
  ) => {
    return {
      ...state,
      store: action.payload,
    };
  },
  [ReduxActionTypes.UPDATE_APP_GLOBAL_STORE]: (
    state: AppDataState,
    action: ReduxAction<Record<string, unknown>>,
  ) => {
    return {
      ...state,
      globalStore: action.payload,
    };
  },
  [ReduxActionTypes.UPDATE_APP_SOCKET_STORE]: (
    state: AppDataState,
    action: any,
  ) => {
    const key: string = action.payload.key;
    let value;
    const oldValue: [] = state.socketData[key] as [];
    if (oldValue) {
      value = [...oldValue, action.payload.payload];
    } else {
      value = [action.payload.payload];
    }
    return {
      ...state,
      socketData: { ...state.socketData, [key]: value },
    };
  },
});

export default appReducer;
