export default {
  widgetName: "MainContainer",
  backgroundColor: "none",
  rightColumn: 1242,
  snapColumns: 16,
  widgetId: "0",
  topRow: 0,
  bottomRow: 1292,
  parentRowSpace: 1,
  type: "CANVAS_WIDGET",
  detachFromLayout: true,
  minHeight: 1292,
  dynamicBindings: {},
  parentColumnSpace: 1,
  leftColumn: 0,
  children: [],
};
