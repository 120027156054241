import { createImmerReducer } from "utils/AppsmithUtils";
import {
  ReduxActionTypes,
  UpdateCanvasPayload,
  ReduxAction,
} from "constants/ReduxActionConstants";
import { WidgetProps } from "widgets/BaseWidget";
import {
  UpdateCanvasLayout,
  UpdateCanvasLayoutHeight,
  UpdateWidgetPropertyPayload,
} from "actions/controlActions";
import { MAIN_CONTAINER_WIDGET_ID } from "../../constants/WidgetConstants";
import { set, uniqBy } from "lodash";

const initialState: CanvasWidgetsReduxState = {};

export type FlattenedWidgetProps = WidgetProps & {
  children?: string[];
};

const canvasWidgetsReducer = createImmerReducer(initialState, {
  [ReduxActionTypes.UPDATE_CANVAS]: (
    state: CanvasWidgetsReduxState,
    action: ReduxAction<UpdateCanvasPayload>,
  ) => {
    return action.payload.widgets;
  },
  [ReduxActionTypes.UPDATE_LAYOUT]: (
    state: CanvasWidgetsReduxState,
    action: ReduxAction<UpdateCanvasPayload>,
  ) => {
    return action.payload.widgets;
  },
  [ReduxActionTypes.UPDATE_CANVAS_LAYOUT]: (
    state: CanvasWidgetsReduxState,
    action: ReduxAction<UpdateCanvasLayout>,
  ) => {
    set(state[MAIN_CONTAINER_WIDGET_ID], "rightColumn", action.payload.width);
  },
  [ReduxActionTypes.UPDATE_CANVAS_LAYOUT_HEIGHT]: (
    state: CanvasWidgetsReduxState,
    action: ReduxAction<UpdateCanvasLayoutHeight>,
  ) => {
    set(state[MAIN_CONTAINER_WIDGET_ID], "bottomRow", action.payload.height);
  },
  [ReduxActionTypes.UPDATE_WIDGET_PROPERTY]: (
    state: CanvasWidgetsReduxState,
    action: ReduxAction<UpdateWidgetPropertyPayload>,
  ) => {
    state[action.payload.widgetId][action.payload.propertyName] =
      action.payload.propertyValue;
  },
});

export interface CanvasWidgetsReduxState {
  [widgetId: string]: FlattenedWidgetProps;
}

export default canvasWidgetsReducer;
