import React, { useCallback, useEffect, useState } from "react";
import { getModalStyle } from "./EditorHeader";
import {
  Button as Buttons,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  RadioProps,
  TextField,
  withStyles,
} from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "axios";
import { AppToaster } from "../../components/editorComponents/ToastComponent";
import { ToastType } from "react-toastify";
import Preload from "../../components/designSystems/appsmith/help/PreLoad";
import moment from "moment";
import { useDispatch } from "react-redux";
import { publishPage } from "../../actions/applicationActions";

const BlueRadio = withStyles({
  root: {
    color: "#303f9f",
    "&$checked": {
      color: "#303f9f",
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

export const VersionModal = (props: any) => {
  const { pageId } = props;
  const [createVersion, setCreateVersion] = useState(false);
  const [value, setValue] = React.useState("");
  const [lastVersion, setLastVersion] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [versions, setVersions] = React.useState([]);
  const [preload, setPreload] = useState<boolean>(true);
  const dispatch = useDispatch();
  const error = useCallback((msg: string) => {
    AppToaster.show({
      message: `${msg}`,
      type: ToastType.ERROR,
    });
  }, []);

  useEffect(() => {
    (async () => {
      if (pageId) {
        try {
          const { data } = await getVersionList(pageId);
          if (data) {
            setVersions(data);
          }

          const lastVersion = await getLastVersion(pageId);
          if (lastVersion) {
            setLastVersion(lastVersion.data?.tag);
          }
          setPreload(false);
        } catch (e) {
          setPreload(false);
          error(e.message);
        }
      }
    })();
  }, [pageId]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const major = `${+parsVersion(lastVersion)[0] + 1}.0.0`;
  const minor = `${parsVersion(lastVersion)[0]}.${+parsVersion(lastVersion)[1] +
    1}.0`;
  const patch = `${parsVersion(lastVersion)[0]}.${
    parsVersion(lastVersion)[1]
  }.${+parsVersion(lastVersion)[2] + 1}`;
  return (
    <div style={getModalStyle()}>
      <div
        style={{
          fontSize: "larger",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>Version</span>
        {createVersion ? (
          <IconButton
            component="span"
            onClick={() => {
              setCreateVersion(false);
            }}
          >
            <ArrowBackIcon></ArrowBackIcon>
          </IconButton>
        ) : (
          <Buttons
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setCreateVersion(true);
            }}
          >
            create
          </Buttons>
        )}
      </div>
      <div style={{ width: "100%", height: "100%", paddingTop: "15px" }}>
        {createVersion ? (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>Description</span>{" "}
              <TextField
                id="outlined-basic"
                style={{ width: "75%" }}
                label="Description"
                variant="outlined"
                onChange={e => {
                  setDescription(e.target.value);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>Version</span>
              <RadioGroup
                row
                value={value}
                color="default"
                onChange={handleChange}
              >
                <FormControlLabel
                  value={major}
                  control={<BlueRadio />}
                  color="default"
                  label={`Major (v${major})`}
                />
                <FormControlLabel
                  value={minor}
                  control={<BlueRadio />}
                  color="default"
                  label={`Minor (v${minor})`}
                />
                <FormControlLabel
                  value={patch}
                  control={<BlueRadio />}
                  color="default"
                  label={`Patch (v${patch})`}
                />
              </RadioGroup>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <Buttons
                variant="contained"
                color="primary"
                size="small"
                onClick={async () => {
                  try {
                    await createVersionApi(pageId, value, description);
                    setCreateVersion(false);
                    props.closeModal();
                  } catch (e) {}
                }}
              >
                Create draft release
              </Buttons>
            </div>
          </div>
        ) : (
          versions.map((item: any, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  margin: "5px",
                  borderBottom: "1px solid silver",
                  alignItems: "center",
                  justifyContent: "space-between",
                  minHeight: "40px",
                }}
              >
                <div
                  style={{
                    width: "calc(100% - 200px)",
                    paddingRight: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>
                      v{item.tag}{" "}
                      {props.publishVersion === item.tag ? (
                        <span style={{ color: "green" }}>live</span>
                      ) : (
                        ""
                      )}
                    </span>
                    <span>{moment(item.date).format("DD.MM.YYYY")}</span>
                  </div>
                  <div style={{ margin: "5px", fontSize: "15px" }}>
                    <span>{item.description}</span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "200px",
                  }}
                >
                  <Buttons
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<ReplayIcon />}
                    style={{
                      width: "90px",
                    }}
                    onClick={async () => {
                      try {
                        await restoreVersion(pageId, item.tag);
                        location.reload();
                      } catch (e) {}
                    }}
                  >
                    Revert
                  </Buttons>
                  <Buttons
                    variant="contained"
                    style={{
                      width: "90px",
                    }}
                    color="primary"
                    size="small"
                    onClick={() => {
                      dispatch(publishPage(pageId, item.tag));
                      props.closeModal();
                      props.setPublishVersion(item.tag);
                    }}
                  >
                    Publish
                  </Buttons>
                </div>
              </div>
            );
          })
        )}
      </div>
      {preload && <Preload />}
    </div>
  );
};
const url = "https://workflow2.ubos.tech/webhook/";
export const getVersionList = (pageId: string) => {
  return axios.post(url + "getVersionList", {
    pageId: pageId,
  });
};
export const publishVersionApi = (version: string, pageId: string) => {
  return axios.post(url + "publishVersion", {
    tag: version,
    pageId: pageId,
  });
};

export const getPublishVersion = (pageId: string) => {
  return axios.post(url + "getPublishVersion", {
    pageId: pageId,
  });
};

export const createVersionApi = (
  pageId: string,
  version: string,
  description: string,
) => {
  return axios.post(url + "createVersion", {
    pageId: pageId,
    tag: version,
    description: description,
  });
};

export const getLastVersion = (pageId: string) => {
  return axios.post(url + "getLatestVersion", {
    pageId: pageId,
  });
};
export const restoreVersion = (pageId: string, version: string) => {
  return axios.post(url + "restoreVersion", {
    pageId: pageId,
    tag: version,
  });
};

function parsVersion(version = "0.0.0") {
  if (version.length == 0) {
    version = "0.0.0";
  }
  const array = version.split(".");
  return array;
}
