import io from "socket.io-client";
import { updateAppSocketStore } from "../actions/pageActions";

const socketMiddleware = () => {
  const socket: any = {};

  const onOpen = (store: any) => (event: any) => {
    console.log("websocket open", event.target.url);
    // store.dispatch(wsConnected(event.target.url));
  };

  const onClose = (store: any) => () => {
    // store.dispatch(wsDisconnected());
  };

  const onMessage = (data: any) => (event: any) => {
    const payload = event.data;
    const [type, body] = payload;
    data.store.dispatch(updateAppSocketStore(data.key, payload));
  };

  // the middleware part of this function
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return store => next => action => {
    switch (action.type) {
      case "WS_CONNECT":
        if (socket[action.payload.socketName]) {
          socket[action.payload.socketName].close();
        }
        //
        // connect to the remote host

        socket[action.payload.socketName] = new WebSocket(
          action.payload.socketUrl,
        );

        // websocket handlers
        socket[action.payload.socketName].onmessage = onMessage({
          store,
          key: action.payload.socketName,
        });
        socket[action.payload.socketName].onclose = onClose(store);
        socket[action.payload.socketName].onopen = onOpen(store);

        break;
      case "WS_DISCONNECT":
        if (socket !== null) {
          socket[action.payload.socketName].close();
        }
        socket[action.payload.socketName] = null;

        break;
      case "MSG":
        const value = JSON.stringify(action.payload.value);
        socket[action.payload.key].send(value);
        break;
      default:
        return next(action);
    }
  };
};
// const makeRequest = (opcode: WebSocketOpcode, data: any) => {
//   return JSON.stringify([opcode, data]);
// };
export default socketMiddleware();
