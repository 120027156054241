import React from "react";
import "./PreLoad.css";
const Preload = (props: any) => {
  return (
    <div className="wrapper_preload_component">
      <div className="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default Preload;
